import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UnitsMetadataStore, IUnitsMetadataState } from '@store/units-metadata-store/units-metadata.store';
import { UnitsStore } from '@store/units-store/units.store';
import { IUnitClass, IUnitGroup } from '@store/types';
import { IUnitTemplate } from '@shared/components/template-unit-filter/components/units-templates/models/units-templates.type';
import { IUnitFull } from '../../modules/monitoring/modules/unit/models/unit';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitsSelector } from '@store/units-store/units.selector';

@Injectable({
	providedIn: 'root'
})
export class UnitsMetadataSelector extends Query<IUnitsMetadataState> {
	constructor(
		private unitsSelector: UnitsSelector,
		protected store: UnitsMetadataStore,
	) {
		super(store);
	}

	getUnitClasses(): IUnitClass[] {
		return this.store.getValue().unitClasses;
	}

	getUnitGroups(): IUnitGroup[] {
		return this.store.getValue().unitGroups;
	}

	getUnitTemplates(): IUnitTemplate[] {
		return this.store.getValue().unitsTemplates;
	}

	getFavoriteUnitIds(): number[] {
		return Array.from(this.store.getValue().favoriteUnitIds.values());
	}

	getFavoriteUnits$(): Observable<IUnitFull[]> {
		return this.select('favoriteUnitIds').pipe(
			map((unitIds: Set<number>) =>
				Array.from(unitIds.values())
					.map((id) => this.unitsSelector.getUnitById(id))
			)
		)
	}

	getFavoriteUnits(): IUnitFull[] {
		return this.getFavoriteUnitIds()
			.map((id) => this.unitsSelector.getUnitById(id));
	}

	isUnitFavorite(unitId: number): boolean {
		return this.store.getValue().favoriteUnitIds.has(unitId);
	}
}
