import {animate, style, transition, trigger} from '@angular/animations';

export const appearanceAnimation = trigger('appearance', [
	transition('void => *', [
		style({opacity: 0, scale: 0.8}),
		animate('.15s ease-in-out',
			style({opacity: 1, scale: 1})
		),
	]),
	transition('* => void', [
		style({opacity: 1, scale: 1}),
		animate('.15s ease-in-out',
			style({opacity: 0, scale: 0.8})
		)
	])
]);
