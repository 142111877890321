import {
	ComponentRef,
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import {PopoverComponent} from '@shared/directives/popover/component/popover.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { sleep } from '@shared/utils';
import { aW } from '@fullcalendar/core/internal-common';

@UntilDestroy()
@Directive({
	selector: '[appPopover]'
})
export class PopoverDirective implements OnChanges {
	@Input() appPopover: string = '';
	@Input() popoverTemplateRef: TemplateRef<Object>;
	@Input() close$: Subject<void>;
	@Input() zIndex: number = 1000000000;

	@Output() isOpen = new EventEmitter<boolean>();

	private popoverComponent: ComponentRef<PopoverComponent> | null = null;
	private background: HTMLDivElement = null;
	private backgroundListener: EventListenerOrEventListenerObject = null;
	private ngUnsubscribe$: Subject<void>;

	constructor(
		private _elementRef: ElementRef,
		private _viewContainerRef: ViewContainerRef,
	) {
		this._elementRef.nativeElement.style.cursor = 'pointer';
	}

	@HostListener('click', ['$event'])
	public onClick(event: Event): void {
		event.stopPropagation();

		const isHavePopover = !!this.popoverComponent;

		if (isHavePopover) {
			this.destroyPopoverComponent();
		} else {
			this.createPopoverComponent();
		}

		this.isOpen.emit(!isHavePopover);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.close$?.currentValue) {
			this.close$
				?.pipe(untilDestroyed(this))
				.subscribe(() => this.onClickOutside());
		}
	}

	private onClickOutside(): void {
		if (this.popoverComponent) {
			this.destroyPopoverComponent();
		}
	}

	private createPopoverComponent(): void {
		this.ngUnsubscribe$ = new Subject<void>();
		this.popoverComponent = this._viewContainerRef.createComponent(PopoverComponent);

		document.body.appendChild(
			this.popoverComponent.location.nativeElement
		);
		this.backgroundListener = (ev: MouseEvent) => {
			this.onClickOutside();
		};
		this._createBackground();

		this._setPopoverProperties();
		// ТАК НАДО, ПЕРВЫЙ РАЗ ОПРЕДЕЛЯЕТ ПОЛОЖЕНИЕ, ВТОРОЙ СТОРОНУ!!!
		this._definitionLocation();
		setTimeout(() => {
			this._definitionLocation();
		});

		this.popoverComponent.instance.close
			.pipe(takeUntil(this.ngUnsubscribe$))
			.subscribe(() => {
				if (this.popoverComponent) {
					this.destroyPopoverComponent();
				}
			});
	}

	public async destroyPopoverComponent(): Promise<void> {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();

		this.popoverComponent.instance.viewPopover = false;
		this.popoverComponent.changeDetectorRef.markForCheck();

		await sleep(150);

		if (!this.popoverComponent) {
			return;
		}

		this.popoverComponent.destroy();
		this.popoverComponent = null;
		this.background.removeEventListener('click', this.backgroundListener);
		this.background.remove();
	}

	private _setPopoverProperties(): void {
		if (this.popoverComponent === null) {
			return;
		}

		this.popoverComponent.instance.popover = this.appPopover;
		this.popoverComponent.instance.templateRef = this.popoverTemplateRef;
	}

	private _definitionLocation(): void {
		if (this.popoverComponent === null) {
			return;
		}

		const {bottom, height, left, right, top, width} =
			this._elementRef.nativeElement.getBoundingClientRect();

		const popoverWidth = this.popoverComponent.location.nativeElement.offsetWidth;
		const calendarHeight = this.popoverComponent.location.nativeElement.offsetHeight;

		if (popoverWidth === 0) {
			this.popoverComponent.location.nativeElement.style.left = 0 + 'px';
		} else if (document.body.offsetWidth > popoverWidth + left) {
			this.popoverComponent.location.nativeElement.style.left = left + 'px';
		} else {
			this.popoverComponent.location.nativeElement.style.left = right - popoverWidth + 'px';
		}

		if (document.body.offsetHeight < calendarHeight + bottom + 10) {
			this.popoverComponent.location.nativeElement.style.top = top - 10 - calendarHeight + 'px';
		} else {
			this.popoverComponent.location.nativeElement.style.top = bottom + 10 + 'px';
		}

		this.popoverComponent.location.nativeElement.style.opacity = '1';
		this.popoverComponent.location.nativeElement.style.zIndex = `${this.zIndex}`;
	}

	private _createBackground(): void {
		this.background = document.createElement('div');

		this.background.style.top = '0px';
		this.background.style.left = '0px';
		this.background.style.position = 'fixed';
		this.background.style.height = '100vh';
		this.background.style.width = '100vw';
		this.background.style.zIndex = `${this.zIndex - 1}`;

		this.background.addEventListener('click', this.backgroundListener);

		document.body.appendChild(this.background);
	}
}
