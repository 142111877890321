import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
	ICreateUnitsTemplate,
	IUnitTemplate,
	IUpdateUnitsTemplate
} from '@shared/components/template-unit-filter/components/units-templates/models/units-templates.type';
import {BaseRepository} from './base-repository';

@Injectable({providedIn: 'root'})
export class UnitsTemplatesRepository extends BaseRepository {
	public createUnitsTemplate(
		payload: ICreateUnitsTemplate
	): Observable<IUnitTemplate> {
		const url = `${this._config.javaApiUrl}/core/unit-template/save`;

		return this._http.post<IUnitTemplate>(url, payload);
	}

	public updateUnitsTemplate(
		payload: IUpdateUnitsTemplate
	): Observable<IUnitTemplate> {
		const url = `${this._config.javaApiUrl}/core/unit-template/save`;

		return this._http.post<IUnitTemplate>(url, payload);
	}

	public selectAsDefault(
		groupId: number,
		isDefault: boolean
	): Observable<IUnitTemplate> {
		const url
			= `${this._config.javaApiUrl}/core/unit-template/default/${groupId}`;

		return this._http.post<IUnitTemplate>(url, {value: isDefault});
	}

	public getUnitsTemplates(): Observable<IUnitTemplate[]> {
		const url = `${this._config.javaApiUrl}/core/unit-template`;

		return this._http.get<IUnitTemplate[]>(url);
	}

	public deleteUnitsTemplate(id: number): Observable<void> {
		const url = `${this._config.javaApiUrl}/core/unit-template/${id}`;

		return this._http.delete<void>(url);
	}
}
