import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ApiRepository } from '@shared/repositories/api.repository';
import { UnitsTemplatesRepository } from '@shared/repositories/units-templates.repository';
import { IUnitClass, IUnitGroup } from '@store/types';
import { IUnitTemplate } from '@shared/components/template-unit-filter/components/units-templates/models/units-templates.type';
import { forkJoin, Observable, Subject } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

export interface IUnitsMetadataState {
	favoriteUnitIds: Set<number>;
	unitClasses: IUnitClass[];
	unitGroups: IUnitGroup[];
	unitsTemplates: IUnitTemplate[];
}

export const DefaultFilterState: IUnitsMetadataState = {
	favoriteUnitIds: new Set<number>,
	unitClasses: [],
	unitGroups: [],
	unitsTemplates: [],
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'unitsMetadataStore'})
export class UnitsMetadataStore extends Store<IUnitsMetadataState> {
	constructor(
		private _apiService: ApiRepository,
		private _unitsTemplatesRepository: UnitsTemplatesRepository,
	) {
		super(DefaultFilterState);
	}

	init(): Observable<IUnitsMetadataState> {
		const favoriteUnitIds$ = this._apiService.getUnitsFavorites();
		const unitClasses$ = this._apiService.getAllUnitClasses();
		const unitGroups$ = this._apiService.getAllUnitGroups();
		const unitsTemplates$ = this._unitsTemplatesRepository.getUnitsTemplates();

		return forkJoin({
			favoriteUnitIds: favoriteUnitIds$,
			unitClasses: unitClasses$,
			unitGroups: unitGroups$,
			unitsTemplates: unitsTemplates$
		})
			.pipe(
				map((res) => {
					const state = {
						favoriteUnitIds: this.castFavoriteArrayToSet(res.favoriteUnitIds),
						unitClasses: res.unitClasses,
						unitGroups: res.unitGroups,
						unitsTemplates: res.unitsTemplates,
					}
					this.update(state);
					return state
				})
			)
	}

	castFavoriteArrayToSet(ids: number[]): Set<number> {
		const set: Set<number> = new Set();

		for (let i = 0; i < ids.length; i++) {
			set.add(ids[i]);
		}

		return set;
	}
}
