import { Query, Store } from '@datorama/akita';

export class Reducer<State> extends Query<State> {
	constructor(
		protected store: Store<State>
	) {
		super(store);
	}


	public rewriteStore<T = any>(nameState: keyof State, value: T): void {
		this.store.update((state: State) => {
			return {
				...state,
				[nameState]: value
			};
		});
	}

	public pushValueInArray<T = any>(nameState: keyof State, value: T): void {
		const tmp = this.store.getValue()[nameState as string];

		tmp.push(value);

		this.store.update((state: State) => {
			return {
				...state,
				[nameState]: [...tmp],
			};
		});
	}

	public updateValueInArrayById<T = any>(nameState: keyof State, value: T, id: number = null): void {
		if (id === null) {
			// @ts-ignore
			id = value.id;
		}
		const tmp = this.store.getValue()[nameState as string];

		const index = tmp.findIndex((item) => item.id === id);
		tmp[index] = value;

		this.store.update((state: State) => {
			return {
				...state,
				[nameState]: [...tmp],
			};
		});
	}

	public updateValueInArrayByIndex<T = any>(nameState: keyof State, value: T, index: number): void {
		const tmp = this.store.getValue()[nameState as string];

		tmp[index] = value;

		this.store.update((state: State) => {
			return {
				...state,
				[nameState]: [...tmp],
			};
		});
	}

	public deleteValueInArrayById(nameState: keyof State, id: number = null): void {
		let tmp = this.store.getValue()[nameState as string];

		tmp = tmp.filter((item) => item.id !== id);

		this.store.update((state: State) => {
			return {
				...state,
				[nameState]: [...tmp],
			};
		});
	}

	public deleteValueInSet<T = any>(nameState: keyof State, value: T): void {
		let tmp: Set<T> = this.store.getValue()[nameState as string];
		tmp.delete(value)

		this.store.update((state: State) => {
			return {
				...state,
				[nameState]: tmp
			}
		})
	}

	public addValueInSet<T = any>(nameState: keyof State, value: T): void {
		let tmp: Set<T> = this.store.getValue()[nameState as string];
		tmp.add(value)

		this.store.update((state: State) => {
			return {
				...state,
				[nameState]: tmp
			}
		})
	}

	public addValueInMap<T = any, K = any>(nameState: keyof State, key: K, value: T): void {
		let tmp: Map<K, T> = this.store.getValue()[nameState as string];
		tmp.set(key, value)

		this.store.update((state: State) => {
			return {
				...state,
				[nameState]: tmp
			}
		})
	}
}
