import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { appearanceAnimation } from '@shared/directives/popover/component/animations/appearance.animation';

@Component({
	selector: '',
	templateUrl: 'popover.component.html',
	styleUrls: ['popover.component.scss'],
	animations: [appearanceAnimation]
})
export class PopoverComponent {
	@Input() popover = '';
	@Input() templateRef: TemplateRef<Object>;
	@Output() close: EventEmitter<void> = new EventEmitter<void>();

	@Input() viewPopover = true;

	public closePopover(): void {
		this.close.emit();
	}
}
