import { NgModule } from '@angular/core';
import { PopoverComponent } from '@shared/directives/popover/component/popover.component';
import { PopoverDirective } from '@shared/directives/popover/popover.directive';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [ PopoverComponent, PopoverDirective ],
	exports: [ PopoverComponent, PopoverDirective ],
	imports: [CommonModule, MatIconModule, MatButtonModule],
	providers: [Document]
})
export class PopoverModule {}
