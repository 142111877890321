<div @appearance *ngIf="viewPopover && !templateRef" class="popover-content">
	{{ popover }}

	<button mat-icon-button class="btn-close" (click)="closePopover()">
		<mat-icon svgIcon="close"></mat-icon>
	</button>
</div>

<div @appearance *ngIf="viewPopover && templateRef">
	<ng-container [ngTemplateOutlet]="templateRef"></ng-container>
</div>
