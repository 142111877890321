import { Injectable } from '@angular/core';
import { Reducer } from '@store/reducer';
import { IUnitsMetadataState, UnitsMetadataStore } from '@store/units-metadata-store/units-metadata.store';

@Injectable({ providedIn: 'root' })
export class UnitsMetadataReducer extends Reducer<IUnitsMetadataState> {
	constructor(private s: UnitsMetadataStore) {
		super(s);
	}
}
